.login-bg {
  width: 100%;
  height: 100vh !important;
  object-fit: cover;
  overflow: hidden;
}

.text-purple {
  color: #521986;
}
Input {
  color: #521986;
}
Input.br-5,
select.br-5 {
  border-radius: 5px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.input-icon {
  position: absolute;
  height: 40px;
  padding-bottom: 17px;
  padding-left: 6px;
}
.password-eye {
  height: 40px;
  background: #fff;
  margin-bottom: 16px;
  border-radius: 0px 5px 5px 0;
  /* right: 60px; */
}
.password-eye1 {
  width: 25px;
}
.eye-position {
  display: flex;
  position: relative;
  top: -36px;
  height: 0px;
  justify-content: flex-end;
}
@media (mix-width: 1281px) {
  .login-bg {
    height: calc(100vh - 60px) !important;
  }
}
.col-8-5 {
  flex: 0 0 auto;
  width: 65% !important;
}
.col-3-5 {
  width: 33% !important;
}

.time-remaining {
  color: #521986;
  font-size: 0.7rem;
  float: right;
}
.react-tel-input .form-control {
  height: 40px !important;
  width: 100% !important;
}
