.dashboard-card1 {
  display: flex;
  border: 1px solid #ffa303;
  border-radius: 10px;
  padding: 7px;
  align-items: center;
  justify-content: space-evenly;
  width: 23%;
  height: 80px;
  background-color: #ffffff;
}
.dashboard-card1.bg-purple {
  display: flex;
  border: 1px solid #602a94;
}

.dashboard-card1.bg-purple:hover {
  border: none;
}
.dashboard-card2 {
  width: 31.5%;
  display: flex;
  border: 1px solid #ffa303;
  border-radius: 10px;
  padding: 7px;
  justify-content: space-evenly;
  height: 100px;
  background-color: #ffffff;
}
.card1-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}
.dashboard-card1-slider {
  width: calc(46% + 20px);
  background: linear-gradient(180deg, #ff8f00 0%, #ffc93f 100%);
  border-radius: 10px;
}

.dashboard-card-slider {
  display: flex;
  padding: 0px 20px;
  align-items: center;
  justify-content: space-around;
  height: 100px;
}

.slider-icon {
  background-color: white;
  padding: 3px;
  height: 40px;
  width: 40px !important;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard-sec-2 {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
}
.dashboard-sec-2-1 {
  width: calc(69% + 40px);
}
.dashboard-sec-2-2 {
  width: 23%;
}
.overview-video,
.recommended-video,
.reminders {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 5px 15px rgb(170 135 166 / 60%);
}
.overview-video,
.reminders {
  margin: 20px 0px;
}

.heading-dash {
  color: #ffa303;
  padding: 15px;
}

.reminder-msg-box {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid #ffa303;
  border-radius: 10px;
  margin: 7px;
  padding: 10px 3px;
}

.reminder-msg-box h6,
.reminder-msg-box p {
  margin: 0px;
}

.reminder-msg-box .reminder-icon {
  background-color: #f0dbff;
  border-radius: 5px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.remainder-content h6 {
  font-size: 0.8rem;
}
.remainder-content p {
  font-size: 0.6rem;
}
.remainder-time {
  color: #ae78e5;
  font-size: 0.7rem;
}

.swiper-slide {
  width: auto !important;
}
.dashboardcard {
  display: flex;
  border: 1px solid #ffa303;
  border-radius: 10px;
  padding: 7px;
  align-items: center;
  justify-content: space-evenly;
  width: 23%;
  height: 80px;
  background: #ffffff;
  transition: all ease 0.7s;
  color: #000;
}
