/* card css */

.card-image-wrapper {
  position: relative;
  width: 100%;
  height: 200px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
}
.card-content-wrapper h2 {
  font-size: 1.7rem;
  font-family: "Inter", sans-serif;
  line-height: 30px;
  color: #000;
  font-weight: 700;
}
.card-content-wrapper p {
  font-size: 0.9rem;
  font-family: "Inter", sans-serif;
  line-height: 25px;
  font-weight: 400;
  font-weight: 500;
  color: #777;
}
.card-image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.3s ease-in-out;
}

.card-footer-right img {
  width: 20px;
  height: 20px;
}

.border-bottom1 {
  border-bottom: 0.4mm solid #7944ae;
}

.button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.button-yellow {
  background-color: #ffa303;
  color: #fff;
  border: 1px solid #ffa303;
  border-radius: 5px;
  font-size: 1rem;
  padding: 8px 15px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.button-yellow:hover {
  background-color: #fff;
  color: #ffa303;
}
.button-yellow span {
  font-weight: 400 !important;
  font-size: 16px;
  margin-left: 5px;
  margin-right: 5px;
}

.card-title {
  color: #000;
}

.active-mygoal {
  background-color: #fff;
}

.border-top-mygoal {
  border-top: 1px solid #602a94;
}
.border-bottom-mygoal {
  border-bottom: 1px solid #602a94;
}

.job-form label {
  font-size: 1rem;
  font-weight: 550;
  color: #7944ae;
  margin: 15px 0px;
}

.job-form.h-70 {
  max-height: 70vh;
  overflow-y: auto;
}
.job-form.h-80 {
  max-height: 80vh;
  overflow-y: auto;
}
.job-form::-webkit-scrollbar {
  width: 3px;
  border-radius: 5px;
  height: 100px;
  background: #ffa303;
}
.job-form::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #602a94;
}

/* tag  */
.tags-input {
  border: 1px solid #7944ae;
  border-radius: 10px;
}

.tags-input input {
  flex: 1;
  border: none;
  font-size: 14px;
  height: 50px;
  padding: 13px 15px;
  width: 100%;
  padding-top: 0;
  background-color: transparent;
}

.tags-input input:focus {
  outline: transparent;
}

#tags {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 8px;
}

#tags h6 {
  color: #7944ae;
  margin: 10.4px 0px;
  align-items: center;
}

.tag {
  width: auto;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #602a94;
  padding: 0 8px;
  font-size: 14px;
  list-style: none;
  border-radius: 6px;
  margin: 0 8px 8px 0;
  background: #f0dbff;
}

.tag .tag-title {
  margin-top: 0px;
  font-size: 1.1rem;
}

.tag .tag-close-icon {
  display: block;
  width: 19px;
  height: 19px;
  padding-top: 3px;
  line-height: 12px;
  text-align: center;
  font-size: 14px;
  margin-left: 8px;
  color: #602a94;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
}

.btn-close:focus {
  outline: 0;
  box-shadow: none !important;
}

.accordion-button:focus {
  box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
  background-color: #dfc0ff69 !important;
  color: #000 !important;
}

.category-scroller {
  max-height: 300px;
  overflow-y: auto;
}

.category-scroller::-webkit-scrollbar {
  width: 2px;
  border-radius: 5px;
  height: 100px;
  background: #602a94;
}
.category-scroller::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #ffa303;
}

.select-interview .css-13cymwt-control,
.select-interview .css-t3ipsp-control {
  height: 65px;
  border-radius: 10px;
  color: #602a94 !important;
  border: 1px solid #521986 !important;
}

.css-1hb7zxy-IndicatorsContainer {
  display: none !important;
}
/* Style for the Box when selected */
.selected-box {
  background-color: #e0f7fa; /* Light blue background for visual feedback */
  border-color: #007bff; /* Highlight border */
}

/* Base style for the Box */
.BoxYellowOutline {
  border: 1px solid #ccc;
  padding: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

/* Hover effect for better user interaction */
.BoxYellowOutline:hover {
  background-color: #f0f0f0;
}
