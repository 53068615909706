.progress-track-goal {
  display: flex;
  position: relative;
  overflow: hidden;
  padding: 0 25px;
}

.progress-track-goal .progress-track-goal-image .image-main {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 10px;
}
.margin-10-bottom {
  /* margin-bottom: 100px; */
}

.progress-track-goal .progress-track-goal-image img {
  width: 10px;
  height: 80px;
  border-radius: 10px;
  top: 0;
  left: 12%;
}

.progress-track-goal-image {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.progress-track-goal-image-1 {
  display: flex;
  padding: 20px 15px;
  flex-direction: column;
}
.opacity-50 {
  opacity: 0.4;
}
