@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500&family=Lato:wght@300;400;700;900&display=swap");

body {
  font-family: "Lato", sans-serif;
  font-size: 16px;
  overflow-x: hidden;
  background-color: #fff7f3 !important;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a {
  text-decoration: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0;
  margin: 0;
  font-family: "Inter", sans-serif;
  color: #515050 !important;
}

.main-content {
  top: 100px;
  /* padding-left: 5px; */
}

.container {
  max-width: 2180px !important;
}
.col-22 {
  width: 22%;
}
.col-78 {
  width: 78%;
}

.Calendar {
  width: 100% !important;
  box-shadow: none !important;
}

.Calendar__day.-selected,
.Calendar__day.-selectedStart,
.Calendar__day.-selectedEnd {
  background: none !important;
  color: #000 !important;
  /* margin-right: 5px; */
  position: relative;

  /* margin-left: 23px; */
}
.Calendar__day.-selected::after {
  background: #ffa303;
  content: "";
  /* width: 100%; */
  border-radius: 50%;
  height: 40px;
  padding-top: 2px;
  width: 40px;
  position: absolute;
  top: 0;
  left: 10;
  z-index: -1;
  color: #fff;
  /* margin-left: 23px; */
}
.Calendar__day:hover {
  background: transparent !important;
  color: #000 !important;
}
.Calendar__day.-ltr {
  min-height: 2.6em;
  font-size: 1.45em;
  /* width: 6rem; */
  padding: 0.7rem;
}

.Calendar__weekDays {
  display: inline-flex !important;
  justify-content: none !important;
  color: var(--cl-color-disabled);
  font-size: 1.2em;
  margin-bottom: 0.7em;
  padding: 0 3em;
  position: relative;
}

.Calendar__weekDay {
  width: 7.1rem !important;
  font-weight: bolder;
  color: black;
  font-size: 16px !important;
  text-decoration: none !important;
  text-align: center;
}

.text-underlined::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: #000;
  margin-top: 0px;
}

.theme-primary {
  background-color: #f0dbff;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  border-radius: 5px;
}
.theme-primary svg {
  height: 45px;
  width: 35px;
}

.col-2-5 {
  width: 20% !important;
  flex: 0 0 auto;
}
.col-9-5 {
  width: 80% !important;
  flex: 0 0 auto;
}

.outer_circle {
  position: relative;
  margin: 50px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #ffffff;
}

.inner_circle {
  background-image: linear-gradient(
    180deg,
    #8500ff 0%,
    #c07bff 50.52%,
    #dbb8ff 100%
  );
  content: "";
  position: absolute;
  top: -10px;
  bottom: -10px;
  right: -10px;
  left: -10px;
  z-index: -1;
  border-radius: inherit;
}

.gradient-wrapper {
  padding: 3px;
  border-radius: 50%;
  display: inline-block;
  background: linear-gradient(180deg, #8500ff 0%, #c07bff 50.52%, #dbb8ff 100%);
  background: linear-gradient(180deg, #8500ff 0%, #c07bff 50.52%, #dbb8ff 100%);
  background: linear-gradient(180deg, #8500ff 0%, #c07bff 50.52%, #dbb8ff 100%);
  background: linear-gradient(180deg, #8500ff 0%, #c07bff 50.52%, #dbb8ff 100%);
}

#maincircle {
  width: 40px;
  height: 40px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  font-size: 24px;
  font-weight: 700;
  border-radius: 50%;
}

.expiry-counter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* margin-top: 20px; */
  width: 20px;
  height: 20px;
  right: -1px;
  top: -7px;
  border-radius: 50%;
  /* left: 20px; */
  border: 1px solid #521986;
  background-color: #fff;
  position: absolute;
}

.relative {
  position: relative;
}

.lastcolumnSeparator .MuiDataGrid-columnSeparator--sideRight {
  display: none !important;
}
.MuiDataGrid-columnSeparator .MuiDataGrid-columnSeparator--sideRight {
  display: none !important;
}
.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-columnSeparator--sideRight {
  display: none !important;
}

.circle-border-50 {
  border-radius: 50%;
  border: 0.1rem solid #521986;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #521986;
  background-color: #fff;
}

.step-counter {
  width: 500px;
  margin: auto;
}
