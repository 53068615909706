.card-head h6 {
  color: #fff !important;
  background-color: #521986;
  padding: 5px 15px;
  text-align: center;
  border-radius: 0px 0px 10px 10px;
}

.que-des {
  /* color: #ffa303; */
  color: #000;
  font-size: 24px;
  font-weight: 700;
}

.report-box {
  background: #ffffff;
  box-shadow: 0px 0.82207px 2.46621px rgba(13, 10, 44, 0.08);
  border-radius: 8.2207px;
}

.report-box p {
  opacity: 0.8;
  font-size: 12px;
}

.res-detail p {
  color: #000 !important;
  font-size: 15px;
}

.res-detail h6 {
  margin: 14px 0px;
  color: #000 !important;
}

.res-box {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0.82207px 2.46621px rgba(13, 10, 44, 0.08);
}

.res-top-img {
  border-radius: 10px 10px 0px 0px;
}

.report-box p,
.report-box h6 {
  margin-bottom: 0px;
}

progress,
progress[role] {
  /* -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: solid 1px;
  background-size: auto; */
  height: 39px;
  width: 100%;
  /* background-color: transparent; */
  margin: -10px 0px;
}

/* // The unordered list */
.skill-list {
  list-style: none;
  margin: 10px 0px;
}

ul.skill-list {
  padding-left: 0;
}

/* // The list item */
.skill {
  position: relative;
}

.skill h3 {
  color: #000;
  left: 1em;
  line-height: 1;
  font-size: 10px;
  /* position: absolute; */
  top: 1em;
}

/* Style the bar colors */
.skill-1::-webkit-progress-value {
  /* background: #e4e6e5; */
  background: #1976d2;
}

.skill-1::-moz-progress-bar {
  /* background: #e4e6e5; */
  background: #1976d2;
}

.skill-2::-webkit-progress-value {
  /* background: #e4e6e5; */
  background: #1976d2;
}

.skill-2::-moz-progress-bar {
  /* background: #e4e6e5; */
  background: #1976d2;
}

.skill-3::-webkit-progress-value {
  /* background: #e4e6e5; */
  background: #1976d2;
}

.skill-3::-moz-progress-bar {
  /* background: #e4e6e5; */
  background: #1976d2;
}

/* // Animation Keyframes */

.res-bottom {
  height: 60px;
  background-color: #521986;
}

.report-front-page {
  /* background-image: url("../../../assets/image/Frame\ 2608489.png"); */
  /* background-image: url("../../../assets/image/Marketing.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
}

.box-border-report {
  padding: 1rem;
  position: relative;
  /* background: linear-gradient(to bottom, #ffa202, #521b88); */
  padding: 3px;
  /* width: max-content; */
  border-radius: 10px;
  background: #521986;
}

.box-border-report.img {
  border-radius: 50%;
}

.box-report {
  background: #ffffff;
  padding: 3px 2px;
  border-radius: 8px;
}

.box-border-report.img .box-report {
  background: #ffffff;
  padding: 3px;
  border-radius: 50%;
}

.box-border-report.img .box-report img {
  height: 100px;
  width: 100px;
}

.about-des {
  color: #521986;
  font-size: 14px;
}

.report-card-tips p {
  /* color: #000 !important; */
  color: #521986 !important;
}

.report-card-tips p span {
  font-weight: 600;
}
.centerText {
  text-align: center;
}
@media print {
  body * {
    visibility: hidden; /* Hide everything in the body when printing... */
  }
  #reportContent,
  #reportContent * {
    visibility: visible; /* ...except the report content and its children */
  }
  #reportContent {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%; /* Set the width of the report to full page */
  }
  .report-front-page {
    background-size: contain;
  }
}
.progress-container {
  width: 100%;
  background-color: #ddd;
}

.progress-bar {
  width: 50%; /* Adjust this dynamically based on progress */
  height: 20px;
  background-color: #4caf50;
}
.page-break {
  page-break-after: always;
}
.chart-container {
  max-width: 100%; /* Adjust based on your layout needs */
  height: auto; /* Keeps the aspect ratio intact */
}
