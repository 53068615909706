.interview-form h5 {
   font-weight: 700;
   font-size: 1.3rem;
   line-height: 30px;
 }
 
 .interview-form h6 {
   font-weight: 600;
   font-size: 1rem;
   line-height: 30px;
 }
 
 .interview-form p,
 .interview-form li {
   font-weight: 500;
   font-size: 1rem;
   line-height: 30px;
 }
 
 .dark-purple-bg {
   background: #48087c;
   border-radius: 15px 15px 0px 0px;
   color: #ffffff;
 }
 
 .skills-box {
   display: flex;
   gap: 10px;
   flex-wrap: wrap;
 }
 
 .skills-box .skill {
   color: #48087c;
   background: #f0dbff;
   border-radius: 10px;
   padding: 5px 20px;
   font-weight: 500;
   font-size: 14px;
   line-height: 25px;
 }
 
 .form-title {
   color: #602a94;
   font-size: 0.9rem;
   font-weight: 500;
 }
 
 .form-input {
   color: #602a94 !important;
   font-size: 0.9rem;
   height: 45px;
   line-height: 31px !important;
   border-color: #602a94 !important;
 }
 
 .pdf-input-box {
   color: #602a94 !important;
 
   display: flex;
   flex-direction: column;
   align-items: center;
   height: 140px !important;
   justify-content: center;
   background-color: #ffffff;
   border: 1px dashed #602a94;
   line-height: 31px !important;
   border-radius: 5px;
 }
 
 .pdf-input-box svg {
   font-size: 2rem !important;
 }
 
 .form-control:focus,
 .form-select:focus {
   box-shadow: 0px 6px 10px 4px rgb(0 0 0 / 15%), 0px 2px 3px rgb(0 0 0 / 30%) !important;
 }
 
 .ans-options {
   display: flex;
   flex-wrap: wrap;
   gap: 10px;
 }
 
 .ans-options .ans-option {
   border: 1px solid #ffa303;
   text-align: center;
   padding: 10px;
   border-radius: 5px;
 }
 
 .ans-options .ans-option h6 {
   color: #602a94;
   margin: 0;
   font-weight: 550;
   font-size: 1rem;
 }
 
 .question-no-page svg {
   background: #ffffff;
   height: 40px;
   cursor: pointer;
   width: 40px;
   padding: 8px;
   box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15),
     0px 1px 3px rgba(0, 0, 0, 0.3);
   border-radius: 360px;
 }
 
 .question-no-page .page {
   color: #602a94;
   font-size: 1.3rem;
   font-weight: 600;
   text-align: center;
   margin: 12px 0;
 }
 
 .checkbox {
   color: #ffa303 !important;
 }
 
 .form-check-input {
   background-color: transparent !important;
   border: 1px solid #ffa303 !important;
   color: #ffa303 !important;
 }
 
 .drag-box {
   position: absolute;
   cursor: move;
   /* color: black; */
   /* max-width: 215px; */
   /* border-radius: 5px; */
   padding: 1em;
   margin: auto;
 
 }
 
 .JoL-progress-and-controls-wrap {
   display: none !important;
 }
 
 .JoL-player-container .end .replay {
   display: none;
 }